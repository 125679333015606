<template>
  <div>
    <template v-if="[this.userRole].includes('admin')">
      <admin></admin>
    </template>
    <template v-else-if="[this.userRole].includes('partner')">
      <partner></partner>
    </template>
    <template v-else-if="[this.userRole].includes('media')">
      <media></media>
    </template>
    <template v-else-if="[this.userRole].includes('agency')">
      <agency></agency>
    </template>
  </div>
</template>

<script>
import admin from './adminIndex.vue'
import partner from './partnerIndex.vue'
import media from './mediaIndex.vue'
import agency from './agencyIndex.vue'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  components: {
    admin,
    partner,
    media,
    agency
  },
  data () {
    return {
    }
  }
}
</script>
