<!-- 어드민 대시보드 -->
<template>
  <v-container
    id='dashboard'
    fluid
    tag='section'>
    <v-row
            align='center'
            justify='center'>
      <v-col cols='12'>
        <base-material-card
                color='green'>
          <template v-slot:heading>
            <h1 class='display-2 font-weight-regular'>
              Dashboard
            </h1>
          </template>
          <v-card-text>
            <v-row justify="space-between" align="center">
              <h2 style="padding: 20px 10px;">
                매출 현황 및 추이
              </h2>
              <v-row justify="end" align="center">
                <v-col cols="2">
                  <v-menu
                          ref="menu1"
                          v-model="datePicker.start.menu"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker.start.date"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="datePicker.start.date"
                              color="secondary"
                              label="Start date"
                              name="start"
                              prepend-icon="mdi-calendar-outline"
                              readonly
                              v-on="on" />
                    </template>

                    <v-date-picker
                            v-model="datePicker.start.date"
                            color="secondary"
                            landscape
                            scrollable >
                      <v-spacer />
                      <v-btn
                              color="secondary"
                              @click="datePicker.start.menu = false" >
                        Cancel
                      </v-btn>

                      <v-btn
                              color="secondary"
                              @click="$refs.menu1.save(datePicker.start.date)" >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <span>-</span>
                <v-col cols="2">
                  <v-menu
                          ref="menu2"
                          v-model="datePicker.end.menu"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker.end.date"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="datePicker.end.date"
                              color="secondary"
                              label="End date"
                              name="end"
                              prepend-icon="mdi-calendar-outline"
                              readonly
                              v-on="on" />
                    </template>

                    <v-date-picker
                            v-model="datePicker.end.date"
                            color="secondary"
                            landscape
                            scrollable >
                      <v-spacer />
                      <v-btn
                              color="secondary"
                              @click="datePicker.end.menu = false" >
                        Cancel
                      </v-btn>

                      <v-btn
                              color="secondary"
                              @click="$refs.menu2.save(datePicker.end.date)" >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-btn
                        :disabled="!vModel.valid"
                        color="success"
                        default
                        @click="submitDate">
                  검색
                </v-btn>
              </v-row>
            </v-row>
            <template v-if="resultDashboard">
              <!-- 대시보드 차트 -->
              <v-row>
                <v-col cols="12" ref="chart1">
                  <fusioncharts
                          :type='chartData.type'
                          :width='chartData.width'
                          :height='chartData.height'
                          :dataFormat='chartData.dataFormat'
                          :dataSource='chartData.dataSource'
                  ></fusioncharts>
                </v-col>
              </v-row>
              <!-- 차트 하단 박스들 -->
              <v-row justify="space-between">
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Page view</h3><br>
                      <h1>{{numReplace(resultDashboard.card.total_page_views)}}</h1>
                      <ul class="mt-2">
                        <li>피드 .js코드가 삽입된 페이지 방문수 (중복 허용)</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Active view</h3><br>
                      <h1>{{numReplace(resultDashboard.card.total_active_views)}}</h1>
                      <ul class="mt-2">
                        <li>피드 노출율(AV / PV) = {{ getActiveViewsRate(resultDashboard.card).toFixed(2) }}%</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Contents view</h3><br>
                      <h1>{{numReplace(resultDashboard.card.total_content_views)}}</h1>
                      <ul class="mt-2">
                        <li>피드-컨텐츠 열람율(CV / AV) = {{ getContentViewsRate(resultDashboard.card).toFixed(2) }}%</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col>
                    <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>총 매출</h3><br>
                      <h1 class="d-inline-block">{{numReplace(totalReplace(resultDashboard.card.total_revenue, resultDashboard.card.total_media_revenue, resultDashboard.card.total_content_revenue  ))}}원</h1>
                      <span class="d-inline-block">(VAT제외)</span>
                      <ul class="mt-4">
                        <li>AV 단가(총 매출 / Active Views): <strong>{{ getActiveViewsPerRevenueUnit(resultDashboard.card).toFixed(2) }}</strong>원</li>
                        <li>PV 단가(총 매출 / Page Views): <strong>{{ getPageViewsPerRevenueUnit(resultDashboard.card).toFixed(2) }}</strong>원</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>자사 수익</h3><br>
                      <h1 class="d-inline-block">{{numReplace(resultDashboard.card.total_revenue)}}원</h1>
                      <h3 class="d-inline-block ml-1">({{percentReplace(resultDashboard.card.total_revenue, totalReplace(resultDashboard.card.total_revenue, resultDashboard.card.total_media_revenue, resultDashboard.card.total_content_revenue))}}%)</h3>
                    </v-card-text>
                  </v-card>
                    <p style="font-size: 13px;margin-top:-8px;margin-left:5px;">* 아인애드에서 정산되는 수익과 같습니다.</p>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>매체 수익</h3><br>
                      <h1 class="d-inline-block">{{numReplace(resultDashboard.card.total_media_revenue)}}원</h1>
                      <h3 class="d-inline-block ml-1">({{percentReplace(resultDashboard.card.total_media_revenue, totalReplace(resultDashboard.card.total_revenue, resultDashboard.card.total_media_revenue, resultDashboard.card.total_content_revenue))}}%)</h3>
                      <ul class="mt-4">
                        <li>AV 단가: <strong>{{ (resultDashboard.card.total_media_revenue / resultDashboard.card.total_active_views).toFixed((2)) }}</strong>원</li>
                        <li>PV 단가: <strong>{{ (resultDashboard.card.total_media_revenue / resultDashboard.card.total_page_views).toFixed((2)) }}</strong>원</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center"> <h3>CP 수익</h3><br>
                      <h1 class="d-inline-block">{{numReplace(resultDashboard.card.total_content_revenue)}}원</h1>
                      <h3 class="d-inline-block ml-1">({{percentReplace(resultDashboard.card.total_content_revenue, totalReplace(resultDashboard.card.total_revenue, resultDashboard.card.total_media_revenue, resultDashboard.card.total_content_revenue))}}%)</h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template v-if="response.dashboard && response.dashboard.media_summary">
              <hr />
              <v-row justify="space-between" align="center" class="ma-0">
                <h2 style="padding: 20px 10px;">
                  매출이 발생된 매체
                </h2>
              </v-row>
              <v-row>
                <p class="pl-6">
                  ※ "PV/UV" 항목에는 2020-08-19 이후 ~ 전일까지만 조회됩니다.
                </p>
              </v-row>
              <v-row>
                <dl class="term_description">
                  <dt>Page Views(PV)</dt>
                  <dd>AIIN_FEED Tag가 포함된 페이지의 Page View</dd>

                  <dt>Unique Visitor(UV)</dt>
                  <dd>AIIN_FEED Tag가 포함된 페이지의 Unique Visitor</dd>

                  <dt>PV / UV</dt>
                  <dd>매체에 방문한 한 사용가(UV) 발생시킨 PageViews. * AIIN_FEED 실적과 직접적인 관련 없음</dd>

                  <dt>Active View</dt>
                  <dd>사용자가 매체 기사 페이지 열람 후 스크롤을 통해 하단 AIIN_FEED 영역에 도달했을때 집계하는 AIIN_FEED 노출량</dd>

                  <dt>Active View(%)</dt>
                  <dd>= "Active Views / Page Views"</dd>

                  <dt>Content View</dt>
                  <dd>AIIN_FEED내 컨텐츠를 열람해본 수</dd>

                  <dt>Content View(%)</dt>
                  <dd>= "Content Views / Active Views"</dd>
                </dl>
              </v-row>
              <v-col cols="12" class="pa-0 ma-0">
                <v-data-table
                  :headers="config.mediaSummaryHeader"
                  :items="response.dashboard.media_summary"
                  :items-per-page="5"
                  item-key="item.media_id"
                  no-data-text="리스트가 없습니다.">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-center">{{ item.placement_id }}</td>
                      <td class="text-center"><v-btn class="success" @click="goReportAds(item)">{{ item.media_name }}</v-btn></td>
                      <td class="text-right">{{ numReplace(item.page_views) }}</td>
                      <td class="text-right">{{ numReplace(item.unique_visitor) }}</td>
                      <td class="text-right">{{ item.page_views_per_uv }}</td>
                      <td class="text-right">{{ numReplace(item.active_views) }}</td>
                      <td class="text-right">{{ item.active_views_rate.toFixed(2) }}%</td>
                      <td class="text-right">{{ numReplace(item.content_views) }}</td>
                      <td class="text-right">{{ (item.content_views / item.active_views * 100).toFixed(2) }}%</td>
                      <td class="text-right font-weight-bold">{{ numReplace(item.whole_revenue.toFixed(2)) }}</td>
                      <td class="text-right font-weight-bold">{{ (item.whole_revenue / item.active_views).toFixed(2) }}</td>
                      <td class="text-right">{{ numReplace(item.mci_revenue.toFixed(2)) }}</td>
                      <td class="text-right font-weight-bold">{{ numReplace(item.media_revenue.toFixed(2)) }}</td>
                      <td class="text-right">{{ numReplace(item.content_revenue.toFixed(2)) }}</td>
                      <td class="text-center">
                        <div title="IN_FEED / NATIVE / IN_CONTENTS">
                          {{ numReplace(item.in_feed_revenue.toFixed(2)) }} ({{ (item.in_feed_revenue / (item.in_feed_revenue + item.native_revenue + item.in_contents_revenue) * 100).toFixed(2) }}%) /
                          {{ numReplace(item.native_revenue.toFixed(2)) }} ({{ (item.native_revenue / (item.in_feed_revenue + item.native_revenue + item.in_contents_revenue) * 100).toFixed(2) }}%) /
                          <br />
                          {{ numReplace(item.in_contents_revenue.toFixed(2)) }} ({{ (item.in_contents_revenue / (item.in_feed_revenue + item.native_revenue + item.in_contents_revenue) * 100).toFixed(2) }}%)
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </template>

            <template v-if="response.dashboard && response.dashboard.cp_summary">
              <v-row justify="space-between" align="center" class="ma-0">
                <h2 style="padding: 20px 10px;">
                  CP 컨텐츠가 기여한 매출
                </h2>
              </v-row>
              <v-col cols="12" class="pa-0 ma-0">
                <v-data-table
                  :headers="config.cpSummaryHeader"
                  :items="response.dashboard.cp_summary"
                  :items-per-page="5"
                  item-key="item.media_id"
                  no-data-text="리스트가 없습니다.">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-center">{{ item.partner_id}}</td>
                      <td class="text-center">{{ item.partner_name }}</td>
                      <td class="text-right font-weight-bold">{{ numReplace(item.deliveried_media_cnt) }}</td>
                      <td class="text-right">{{ numReplace(item.viewed_content_cnt) }}</td>
                      <td class="text-right">{{ numReplace(item.content_viewable_impressions) }}</td>
                      <td class="text-right font-weight-bold">{{ numReplace(item.whole_revenue.toFixed(2)) }}</td>
                      <td class="text-right">{{ numReplace(item.mci_revenue.toFixed(2)) }}</td>
                      <td class="text-right">{{ numReplace(item.media_revenue.toFixed(2)) }}</td>
                      <td class="text-right">{{ numReplace(item.content_revenue.toFixed(2)) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </template>

            <template v-if="response.dashboard && response.dashboard.active_view_unit_table">
              <v-row justify="space-between" align="center" class="ma-0">
                <h2 style="padding: 20px 10px;">
                  일별 매체의 ActiveView 단가표
                </h2>
              </v-row>
              <v-col cols="12" class="pa-0 ma-0">
                <ul>
                  <li>일별 ActiveView 단가 (단위: 원)</li>
                </ul>
                <v-data-table
                  :items="response.dashboard.active_view_unit_table"
                  :items-per-page="5"
                  :headers="getTableHeaders(response.dashboard.active_view_unit_table[0])"
                  item-key="item.media_id"
                  no-data-text="리스트가 없습니다.">
                  <template v-slot:item="{ item }">
                    <tr @click="goReportAds(item)">
                      <td style="min-width: 100px;">{{ item.media_name }}</td>
                      <td style="min-width: 80px;" class="text-center">{{ getTextByRsTypeCode(item) }}</td>
                      <td style="min-width: 110px;" class="text-right">{{ numReplace(parseFloat(item.whole_media_revenue).toFixed(2)) }}</td>
                      <td class="text-right">{{ numReplace(item.whole_views) }}</td>
                      <td class="text-right">{{ numReplace(parseFloat(item.whole_unit).toFixed(2)) }}</td>

                      <td v-for="header in getDynamicColumns(item)"
                          :key="header.value"
                          style="min-width: 100px;"
                          class="text-right"
                      >
                        {{ item[header.value] }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </template>

          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
          'top': true,
          'right': true
        }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'
import { chart } from '../../mixins/chart.js'

export default {
  mixins: [ common, chart ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        dashboard: null,
      },
      vModel: {
        valid: false,
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
      },
      config: {
        mediaSummaryHeader: [
          { text: '매체ID', value: 'placement_id', align: 'center' },
          { text: '매체명', value: 'media_name', align: 'center' },
          { text: 'Page Views', value: 'page_views', align: 'right' },
          { text: 'Unique Visitor', value: 'unique_visitor', align: 'right' },
          { text: 'PV / UV', value: 'page_views_per_uv', align: 'right' },
          { text: 'Active View', value: 'active_views', align: 'right' },
          { text: 'Active View(%)', value: 'active_views_rate', align: 'right' },
          { text: 'Contents View', value: 'content_views', align: 'right' },
          { text: 'Contents View(%)', value: 'content_views', align: 'right' },
          { text: '총 매출', value: 'whole_revenue', align: 'right' },
          { text: '총 매출 단가', value: 'whole_revenue_unit', align: 'right' },
          { text: '자사 수익', value: 'mci_revenue', align: 'right' },
          { text: '매체 수익', value: 'media_revenue', align: 'right' },
          { text: 'CP 수익', value: 'content_revenue', align: 'right' },
          { text: '광고 타입별 수익 (IN_FEED / NATIVE / IN_CONTENTS)', value: 'content_revenue', align: 'center' },
        ],
        cpSummaryHeader: [
          { text: 'CP ID', value: 'partner_id', align: 'center' },
          { text: 'CP명', value: 'partner_name', align: 'center' },
          { text: '게재 매체 수', value: 'deliveried_media_cnt', align: 'right' },
          { text: '조회된 컨텐츠 개수', value: 'viewed_content_cnt', align: 'right' },
          { text: '컨텐츠 유효 노출량', value: 'content_viewable_impressions', align: 'right' },
          { text: '총 매출', value: 'whole_revenue', align: 'right' },
          { text: '자사 수익', value: 'mci_revenue', align: 'right' },
          { text: '매체 수익', value: 'media_revenue', align: 'right' },
          { text: 'CP 수익', value: 'content_revenue', align: 'right' },
        ],
      },
      datePicker: {
        start: {
          date: '',
          menu: false
        },
        end: {
          date: '',
          menu: false
        }
      },
      chartData: {}
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 날짜 검색시 */
    submitDate (val) {
      let start = this.datePicker.start.date
      let end = this.datePicker.end.date
      let startNum = Number(start.replace(/-/gi, ''))
      let endNum = Number(end.replace(/-/gi, ''))
      if (startNum > endNum) {
        this.snack('warning', '시작일을 종료일보다 앞날짜로 선택하세요.')
      } else {
        let send = {
          start: start,
          end: end
        }
        sessionStorage.setItem('cdpDate', JSON.stringify(send))
        this.$router.push({ 'name': 'Dashboard', query: send })
        this.refresh()
      }
    },
    /* 페이지 로드시 */
    refresh () {
      if ([this.userRole].includes('admin')) {
        if (this.$route.query.start) {
          this.datePicker.start.date = this.$route.query.start
          this.datePicker.end.date = this.$route.query.end
        } else if (sessionStorage.getItem('cdpDate')) {
          let date = JSON.parse(sessionStorage.getItem('cdpDate'))
          this.datePicker.start.date = date.start
          this.datePicker.end.date = date.end
        } else {
          this.datePicker.start.date = this.$moment(new Date()).add(-6, 'days').format('YYYY-MM-DD')
          this.datePicker.end.date = this.$moment(new Date()).format('YYYY-MM-DD')
        }
        let send = {
          sdate: this.datePicker.start.date,
          edate: this.datePicker.end.date
        }
        this.response.dashboard = null
        this.chartData = []
        this.getDashboard(send)
      } else {
        this.$router.push({ name: 'UserLogin' })
      }
    },
    /* 대시보드 차트 정보 가져오기 */
    getDashboard (send) {
      this.xhttp({
        url: '/reports/dashboard/admin',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.dashboard = response.data.data
          if (this.response.dashboard.chart.data[0].length > 0) {
            for (let v in this.response.dashboard.chart.data) {
              this.response.dashboard.chart.data[v] = this.response.dashboard.chart.data[v]
            }
            let data = JSON.parse(JSON.stringify(this.configChartData))
            data.type = 'stackedcolumn2dlinedy'
            data.dataSource.categories[0].category = this.response.dashboard.label
            let label = ['자사 수익', '매체 수익', 'CP 수익']
            data.dataSource.chart.yaxisname = '수익 (원)'
            data.dataSource.chart.syaxisname = 'view'
            for (let i = 0; i < 3; i++) {
              data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartSet)))
              data.dataSource.dataset[i].seriesname = label[i]
              data.dataSource.dataset[i].data = this.response.dashboard.chart.data[i + 2]
            }
            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartAdd)))
            data.dataSource.dataset[3].seriesname = 'Active view'
            data.dataSource.dataset[3].data = this.response.dashboard.chart.data[0]
            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartAdd)))
            data.dataSource.dataset[4].seriesname = 'Contents view'
            data.dataSource.dataset[4].data = this.response.dashboard.chart.data[1]
            this.chartData = data
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    goReportAds (v) {
      let send = {
        media: v.media_id,
        start: this.datePicker.start.date,
        end: this.datePicker.end.date,
      }
      console.log('# send: ', send)
      this.$router.push({ name: 'reportAds', query: send })
    },
    getDynamicColumns (item) {
      let columns = []
      for (const key of Object.keys(item)) {
        if (!/^\d{4}-\d{2}-\d{2}/g.exec(key)) {
          continue
        }

        columns.push({ text: key, value: key, align: 'center' })
      }

      return columns
    },
    getTableHeaders (item) {
      let headers = [
        { text: '매체명', value: 'media_name', align: 'center' },
        { text: '정산 방식', value: 'rs_type', align: 'center' },
        { text: '매체 수익', value: 'whole_media_revenue', align: 'center' },
        { text: 'ActiveView', value: 'whole_views', align: 'center' },
        { text: '단가', value: 'whole_unit', align: 'center' },
      ]

      let dynamicColumns = this.getDynamicColumns(item)
      headers = headers.concat(dynamicColumns)

      return headers
    },
    getTextByRsTypeCode (media) {
      let rsType = media.rs_type
      let commission = media.default_commission
      if (rsType === 'rs') {
        return `수익쉐어`
      } else if (rsType === 'cpm') {
        return `CPM 정산 (${Math.floor(commission)}원)`
      }
    },
    getActiveViewsRate (item) {
      return item.total_active_views / item.total_page_views * 100
    },
    getContentViewsRate (item) {
      return item.total_content_views / item.total_active_views * 100
    },
    getActiveViewsPerRevenueUnit (item) {
      let revenue = item.total_revenue + item.total_media_revenue + item.total_content_revenue
      return revenue / item.total_active_views
    },
    getPageViewsPerRevenueUnit (item) {
      let revenue = item.total_revenue + item.total_media_revenue + item.total_content_revenue
      return revenue / item.total_page_views
    }
  },
  computed: {
    resultDashboard () {
      if (this.response.dashboard) {
        return this.response.dashboard
      } else {
        return ''
      }
    },
  },
  watch: {
    datePicker: {
      handler (val) {
        if (val.start.date && val.end.date) {
          this.vModel.valid = true
        }
      },
      deep: true
    }
  }
}
</script>

<style>
  .term_description {
    background-color: #ededed;
    border: 1px #555555 solid;
    padding: 8px;
    margin: 8px 16px;
  }

  .term_description dt {
    font-weight: bolder;
    margin-top: 8px;
  }

  .term_description dd {
    padding-left: 3em;
  }
</style>
