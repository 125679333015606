<!-- 에이젼시 대시보드 -->
<template>
  <v-container id='dashboard' fluid tag='section'>
    <v-row align='center' justify='center'>
      <v-col cols='12'>
        <base-material-card color='green'>
          <template v-slot:heading>
            <h1 class='display-2 font-weight-regular'>
              Dashboard
            </h1>
          </template>
          <v-card-text>
            <v-row justify="space-between" align="center">
              <h2 style="padding: 20px 10px;">
                매출 현황 및 추이
              </h2>
              <v-row justify="end" align="center">
                <v-col cols="2">
                  <v-menu
                          ref="menu1"
                          v-model="datePicker.start.menu"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker.start.date"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="datePicker.start.date"
                              color="secondary"
                              label="Start date"
                              name="start"
                              prepend-icon="mdi-calendar-outline"
                              readonly
                              v-on="on" />
                    </template>

                    <v-date-picker
                            v-model="datePicker.start.date"
                            color="secondary"
                            landscape
                            scrollable >
                      <v-spacer />
                      <v-btn
                              color="secondary"
                              @click="datePicker.start.menu = false" >
                        Cancel
                      </v-btn>

                      <v-btn
                              color="secondary"
                              @click="$refs.menu1.save(datePicker.start.date)" >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <span>-</span>
                <v-col cols="2">
                  <v-menu
                          ref="menu2"
                          v-model="datePicker.end.menu"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker.end.date"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="datePicker.end.date"
                              color="secondary"
                              label="End date"
                              name="end"
                              prepend-icon="mdi-calendar-outline"
                              readonly
                              v-on="on" />
                    </template>

                    <v-date-picker
                            v-model="datePicker.end.date"
                            color="secondary"
                            landscape
                            scrollable >
                      <v-spacer />
                      <v-btn
                              color="secondary"
                              @click="datePicker.end.menu = false" >
                        Cancel
                      </v-btn>

                      <v-btn
                              color="secondary"
                              @click="$refs.menu2.save(datePicker.end.date)" >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-btn
                        :disabled="!vModel.valid"
                        color="success"
                        default
                        @click="submitDate">
                  검색
                </v-btn>
              </v-row>
            </v-row>
            <template v-if="resultDashboard">
              <!-- 대시보드 차트 -->
              <v-row>
                <v-col cols="12" ref="chart1">
                  <fusioncharts
                          :type='chartData.type'
                          :width='chartData.width'
                          :height='chartData.height'
                          :dataFormat='chartData.dataFormat'
                          :dataSource='chartData.dataSource'
                  ></fusioncharts>
                </v-col>
              </v-row>
              <!-- 차트 하단 박스들 -->
              <v-row justify="space-between">
                <v-col>
                    <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Active view</h3><br>
                      <h1>{{numReplace(resultDashboard.card.total_active_views)}}</h1>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                    <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Contents view</h3><br>
                      <h1>{{numReplace(resultDashboard.card.total_content_views)}}</h1>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>매체 수익</h3><br>
                      <h1 class="d-inline-block">{{numReplace(resultDashboard.card.total_media_revenue)}}원</h1>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center"> <h3>eCPM</h3><br>
                      <h1 class="d-inline-block"><strong>{{ numReplace((resultDashboard.card.total_media_revenue / resultDashboard.card.total_active_views).toFixed(3) * 1000) }}</strong>원</h1>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template v-if="response.dashboard && response.dashboard.media_summary">
              <hr />
              <v-row justify="space-between" align="center" class="ma-0">
                <h2 style="padding: 20px 10px;">
                  매출이 발생된 매체
                </h2>
              </v-row>
              <v-col cols="12" class="pa-0 ma-0">
                <v-data-table
                  :headers="config.mediaSummaryHeader"
                  :items="response.dashboard.media_summary"
                  :items-per-page="5"
                  item-key="item.media_id"
                  no-data-text="리스트가 없습니다.">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-center">{{ item.media_id }}</td>
                      <td class="text-center"><v-btn class="success" @click="goReportAds(item)">{{ item.media_name }}</v-btn></td>
                      <td class="text-right">{{ numReplace(item.active_views) }}</td>
                      <td class="text-right">{{ numReplace(item.content_views) }}</td>
                      <td class="text-right font-weight-bold">{{ numReplace(item.media_revenue.toFixed(2)) }}</td>
                      <td class="text-right font-weight-bold">{{ (item.media_revenue / item.active_views).toFixed(2) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </template>
            <template v-if="response.dashboard && response.dashboard.active_view_unit_table">
              <v-row justify="space-between" align="center" class="ma-0">
                <h2 style="padding: 20px 10px;">
                  일별 매체의 ActiveView 단가표
                </h2>
              </v-row>
              <v-col cols="12" class="pa-0 ma-0">
                <ul>
                  <li>일별 ActiveView 단가 (단위: 원)</li>
                </ul>
                <v-data-table
                  :items="response.dashboard.active_view_unit_table"
                  :items-per-page="5"
                  :headers="getTableHeaders(response.dashboard.active_view_unit_table[0])"
                  item-key="item.media_id"
                  no-data-text="리스트가 없습니다.">
                  <template v-slot:item="{ item }">
                    <tr @click="goReportAds(item)">
                      <td style="min-width: 100px;">{{ item.media_name }}</td>
                      <td style="min-width: 80px;" class="text-center">{{ getTextByRsTypeCode(item) }}</td>
                      <td class="text-right">{{ numReplace(item.whole_views) }}</td>
                      <td style="min-width: 110px;" class="text-right">{{ numReplace(parseFloat(item.whole_media_revenue).toFixed(2)) }}</td>
                      <td class="text-right">{{ numReplace(parseFloat(item.whole_unit).toFixed(2)) }}</td>
                      <td v-for="header in getDynamicColumns(item)"
                          :key="header.value"
                          style="min-width: 100px;"
                          class="text-right"
                      >
                        {{ item[header.value] }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </template>

          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
          'top': true,
          'right': true
        }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'
import { chart } from '../../mixins/chart.js'

export default {
  mixins: [ common, chart ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        dashboard: null,
      },
      vModel: {
        valid: false,
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
      },
      config: {
        mediaSummaryHeader: [
          { text: '매체ID', value: 'media_id', align: 'center' },
          { text: '매체명', value: 'media_name', align: 'center' },
          { text: 'Active View', value: 'active_views', align: 'right' },
          { text: 'Contents View', value: 'content_views', align: 'right' },
          { text: '매체 수익', value: 'media_revenue', align: 'right' },
          { text: '수익 단가', value: 'media_revenue_unit', align: 'right' },
        ],
      },
      datePicker: {
        start: {
          date: '',
          menu: false
        },
        end: {
          date: '',
          menu: false
        }
      },
      chartData: {}
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 날짜 검색시 */
    submitDate (val) {
      let start = this.datePicker.start.date
      let end = this.datePicker.end.date
      let startNum = Number(start.replace(/-/gi, ''))
      let endNum = Number(end.replace(/-/gi, ''))
      if (startNum > endNum) {
        this.snack('warning', '시작일을 종료일보다 앞날짜로 선택하세요.')
      } else {
        let send = {
          start: start,
          end: end
        }
        sessionStorage.setItem('cdpDate', JSON.stringify(send))
        this.$router.push({ 'name': 'Dashboard', query: send })
        this.refresh()
      }
    },
    /* 페이지 로드시 */
    refresh () {
      if ([this.userRole].includes('agency')) {
        if (this.$route.query.start) {
          this.datePicker.start.date = this.$route.query.start
          this.datePicker.end.date = this.$route.query.end
        } else if (sessionStorage.getItem('cdpDate')) {
          let date = JSON.parse(sessionStorage.getItem('cdpDate'))
          this.datePicker.start.date = date.start
          this.datePicker.end.date = date.end
        } else {
          this.datePicker.start.date = this.$moment(new Date()).add(-6, 'days').format('YYYY-MM-DD')
          this.datePicker.end.date = this.$moment(new Date()).format('YYYY-MM-DD')
        }
        let send = {
          sdate: this.datePicker.start.date,
          edate: this.datePicker.end.date
        }
        this.response.dashboard = null
        this.chartData = []
        this.getDashboard(send)
      } else {
        this.$router.push({ name: 'UserLogin' })
      }
    },
    /* 대시보드 차트 정보 가져오기 */
    getDashboard (send) {
      this.xhttp({
        url: '/reports/dashboard/agency',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.dashboard = response.data.data
          if (this.response.dashboard.chart.data[0].length > 0) {
            for (let v in this.response.dashboard.chart.data) {
              this.response.dashboard.chart.data[v] = this.response.dashboard.chart.data[v]
            }
            let data = JSON.parse(JSON.stringify(this.configChartData))
            data.type = 'stackedcolumn2dlinedy'
            data.dataSource.categories[0].category = this.response.dashboard.label
            data.dataSource.chart.yaxisname = '수익 (원)'
            data.dataSource.chart.syaxisname = 'view'
            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartSet)))
            data.dataSource.dataset[0].seriesname = '매체 수익'
            data.dataSource.dataset[0].data = this.response.dashboard.chart.data[0]
            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartAdd)))
            data.dataSource.dataset[1].seriesname = 'Contents view'
            data.dataSource.dataset[1].data = this.response.dashboard.chart.data[1]
            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartAdd)))
            data.dataSource.dataset[2].seriesname = 'Active view'
            data.dataSource.dataset[2].data = this.response.dashboard.chart.data[2]
            this.chartData = data
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    goReportAds (v) {
      let send = {
        media: v.media_id,
        start: this.datePicker.start.date,
        end: this.datePicker.end.date,
      }
      console.log('# send: ', send)
      this.$router.push({ name: 'reportAds', query: send })
    },
    getDynamicColumns (item) {
      let columns = []
      for (const key of Object.keys(item)) {
        if (!/^\d{4}-\d{2}-\d{2}/g.exec(key)) {
          continue
        }

        columns.push({ text: key, value: key, align: 'center' })
      }

      return columns
    },
    getTableHeaders (item) {
      let headers = [
        { text: '매체명', value: 'media_name', align: 'center' },
        { text: '정산 방식', value: 'rs_type', align: 'center' },
        { text: 'ActiveView', value: 'whole_views', align: 'center' },
        { text: '매체 수익', value: 'whole_media_revenue', align: 'center' },
        { text: '수익 단가', value: 'whole_unit', align: 'center' },
      ]

      let dynamicColumns = this.getDynamicColumns(item)
      headers = headers.concat(dynamicColumns)

      return headers
    },
    getTextByRsTypeCode (media) {
      let rsType = media.rs_type
      let commission = media.default_commission
      if (rsType === 'rs') {
        return `수익쉐어`
      } else if (rsType === 'cpm') {
        return `CPM 정산 (${Math.floor(commission)}원)`
      }
    }
  },
  computed: {
    resultDashboard () {
      if (this.response.dashboard) {
        return this.response.dashboard
      } else {
        return ''
      }
    },
  },
  watch: {
    datePicker: {
      handler (val) {
        if (val.start.date && val.end.date) {
          this.vModel.valid = true
        }
      },
      deep: true
    }
  }
}
</script>
